import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../../components/Layout';
import Articles from '../../components/Articles';
import Header from '../../components/Header';
import {
  ESPANIOL_FAKE,
  ESPANIOL_ORIGINAL,
  ORTOGRAFIA_FAKE,
  ORTOGRAFIA_ORIGINAL,
  GRAMATICA_FAKE,
  GRAMATICA_ORIGINAL,
  ALEMAN_FAKE,
  ALEMAN_ORIGINAL,
  SILABA_FAKE,
  SILABA_ORIGINAL,
  ANALISIS_SINTACTICO_FAKE,
  ANALISIS_SINTACTICO_ORIGINAL,
  INGLES_FAKE,
  INGLES_ORIGINAL,
  TIEMPO_VERBAL_FAKE,
  TIEMPO_VERBAL_ORIGINAL,
  GENERO_FAKE,
  GENERO_ORIGINAL,
} from '../../constants';
import Content from '../../components/Content';
import Seo from '../../components/SEO';

function Tags({ pageContext, data }) {
  const { tag } = pageContext;
  const { edges, totalCount } = data.allMarkdownRemark;

  const getTag = (tag) => {
    switch (tag) {
      case ESPANIOL_FAKE:
        return `${ESPANIOL_ORIGINAL}`;
      case ORTOGRAFIA_FAKE:
        return `${ORTOGRAFIA_ORIGINAL}`;
      case GRAMATICA_FAKE:
        return `${GRAMATICA_ORIGINAL}`;
      case ALEMAN_FAKE:
        return `${ALEMAN_ORIGINAL}`;
      case SILABA_FAKE:
        return `${SILABA_ORIGINAL}`;
      case ANALISIS_SINTACTICO_FAKE:
        return `${ANALISIS_SINTACTICO_ORIGINAL}`;
      case INGLES_FAKE:
        return `${INGLES_ORIGINAL}`;
      case TIEMPO_VERBAL_FAKE:
        return `${TIEMPO_VERBAL_ORIGINAL}`;
      case GENERO_FAKE:
        return `${GENERO_ORIGINAL}`;
      default:
        return `${tag}`;
    }
  };

  return (
    <Layout>
      <Seo />
      <Header title={getTag(tag)} subtitle={`${totalCount} artículos encontrados`} />
      <Content isHome>
        <Articles articles={edges} />
      </Content>
    </Layout>
  );
}

export default Tags;

export const TagsQuery = graphql`query ($tag: String) {
    allMarkdownRemark(
        limit: 2000
        sort: {fields: [frontmatter___date], order: DESC}
        filter: {frontmatter: {tags: {in: [$tag]}, isPublished: {eq: true}}}
    ) {
        totalCount
        edges {
            node {
                html
                frontmatter {
                    path
                    authors
                    date(formatString: "DD [de] MMMM, YYYY", locale: "es")
                    tags
                    title
                    description
                    homeImage {
                        childImageSharp {
                            gatsbyImageData(width: 800, layout: CONSTRAINED)
                        }
                    }
                }
                excerpt
            }
        }
    }
}
`;
